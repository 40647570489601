import { defineStore } from "pinia";
import {
  Routes,
  HEADER_ACCOUNT_API_KEY,
  HEADER_TEAM_API_KEY,
  HEADER_MEMBER_API_KEY,
  apiServerUrl,
} from "~/constants";

const state = {
  billingDetail: null,
  billingSet:null,
  teamBillingDetail:null,
  accountState: "",
  billingDuration: 0,
  contactDiscountPercentage:0,
  promotionDurationApplicable:1,
  promoCodeOfferText: "",
  isComponentModalActive: false,
  instructionMessage : null,
  domainInstructionMessage : null,
  hasAccountExpanded: false,
  accountExpansionData: null,
  paymentForm: {
    stripeToken: "",
    paymentType: "Upgrade",
    planId: "Business",
    contacts: "0",
    price: "999",
    sendingType: "0",
    planDuration: "Monthly",
    promoCode: "",
    hutk: ""
  }
};

export const useSettingStore = defineStore("settingStore", {
  state: () => state,
  actions: {
    setResource({ resource, value }) {
      this[resource] = value;
    },
    setState({ value }) {
      Object.assign(this, value);
    },

    resetPaymentForm() {
      this.setResource({ resource: "paymentForm", value: {
        stripeToken: "",
        paymentType: "Upgrade",
        planId: "Business",
        contacts: "1000",
        price: "999",
        sendingType: "0",
        planDuration: "Monthly",
        promoCode: "",
        hutk: "",
      } });
    },

    getBillingDetails() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}account/setting/billing`, {
          method: "GET",
          headers: {
            [HEADER_ACCOUNT_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const error = res.error.value;
            if (error) {
              console.log("Setting Billing error : ", error);
              reject(error);
            } else {
              console.log("Setting Billing data : ", res.data.value);
              this.setResource({resource: "billingDetail", value: res.data.value.billing,});
              this.setResource({resource: "billingSet", value: res.data.value.billingSet,});
              this.setResource({resource: "teamBillingDetail", value: res.data.value.TeamBillingDetails,});
              resolve(res.data.value);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    upgradeClicked() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/payment/upgrade`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const error = res.error.value;
            if (error) {
              console.log("upgrade clicked error : ", error);
              reject(error);
            } else {
              console.log("upgrade clicked data : ", res.data.value);
              resolve(res.data.value);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    purchasePlan() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/payment`, {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: this.paymentForm,
        })
          .then((res) => {
            const error = res.error.value;
            if (error) {
              console.log("payment purchase error : ", error);
              reject(error);
            } else {
              console.log("payment purchase data : ", res.data.value);
              resolve(res.data.value);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    getBillingState() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}team/payment/info`, {
          method: "GET",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const error = res.error.value;
            if (error) {
              console.log("get billing state error : ", error);
              reject(error);
            } else {
              console.log("get billing state data : ", res.data.value);
              this.setResource({resource: "accountState", value: res.data.value.accountState});
              this.setResource({resource: "billingDuration", value: res.data.value.billingDuration});
              this.setResource({resource: "contactDiscountPercentage", value: res.data.value.contactDiscountPercentage,});
              this.setResource({resource: "promotionDurationApplicable", value: res.data.value.promotionDurationApplicable,});
              this.setResource({resource: "promoCodeOfferText", value: res.data.promoCodeOfferText,});
              resolve(res.data.value);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },

    sendDomainInstruction() {
      return new Promise((resolve, reject) => {
        const globalStore = useGlobalStore();
        const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = globalStore.$state?.authInfo?.member?.apiKey ?? "";
  
        console.log("domain instruction message",state.domainInstructionMessage)

        useFetch(apiServerUrl() + "team/senddomaininstruction", {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(state.domainInstructionMessage),
        }).then((res) => {
            const instructionRes = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log("send domain instruction error: ", error);
              reject(error);
            } else {
              console.log("send domain instruction response : ", instructionRes)
              resolve(instructionRes);
            }
          }
        ).catch((e) => {
          reject(e);
        });
      });
    },

    sendInstruction() {
      return new Promise((resolve, reject) => {
        const globalStore = useGlobalStore();
        const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
        const memberApiKey = globalStore.$state?.authInfo?.member?.apiKey ?? "";
  
        console.log("instruction message",state.instructionMessage)
        useFetch(apiServerUrl() + "team/sendinstruction", {
          method: "POST",
          headers: {
            [HEADER_TEAM_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
          body: JSON.stringify(state.instructionMessage),
        }).then((res) => {
            const instructionRes = res.data.value;
            const error = res.error.value;
            if (error) {
              console.log("send instruction error: ", error);
              reject(error);
            } else {
              console.log("send instruction response : ", instructionRes)
              resolve(instructionRes);
            }
          }
        ).catch((e) => {
          reject(e);
        });
      });
    },

    getAccountExpansionDetails() {
      const store = useGlobalStore();
      return new Promise((resolve, reject) => {
        const apiKey = store.$state?.authInfo?.account?.apiKey ?? "";
        const memberApiKey = store.$state?.authInfo?.member?.apiKey ?? "";
        useFetch(`${apiServerUrl()}account/expansion`, {
          method: "GET",
          headers: {
            [HEADER_ACCOUNT_API_KEY]: apiKey,
            [HEADER_MEMBER_API_KEY]: memberApiKey,
          },
        })
          .then((res) => {
            const error = res.error.value;
            if (error) {
              console.log("Account expansion error : ", error);
              reject(error);
            } else {
              console.log("Account expansion data : ", res.data.value);
              this.setResource({resource: "hasAccountExpanded", value: res.data.value.hasExpanded,});
              this.setResource({resource: "accountExpansionData", value: res.data.value.accountExpansionData,});
              resolve(res.data.value);
            }
          })
          .catch((error) => {
            console.log("error -> ", error);
            reject(error);
          });
      });
    },
  },
});